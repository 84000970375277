.ant-form {
  max-width: 100%;
}

.search-box-hide {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  transition: all 0s ease-in-out;
}

.search-box-expand {
  width: 100%;
  height: auto;
  transition: all 0.5s ease-in-out;
}
