.meta-data-container .ant-collapse-content-box {
  display: flex;
}
.preview-container,
.preview-form-container {
  flex-basis: 50%;
}
.preview-form-container {
  margin-right: 2.4rem;
}
.seo-container {
  display: flex;
  width: 100%;
  margin-bottom: 2.4rem;
  padding: 20px 30px 16px;
  border: 1px solid #18191b;
  font-family: Arial, sans-serif;
  background: #fff;
  border-radius: 3px;
}
.seo-preview {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin-bottom: 28px;
  width: 100%;
}
.seo-container .google-logo {
  width: 92px;
  height: 30px;
  margin-right: 32px;
}
.meta-data-container svg {
  position: relative;
  bottom: 1px;
  width: 18px;
  margin-right: 8px;
}
.seo-search-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 28px;
  background: #f4f5f6;
  border-radius: 14px;
}
.seo-search-bar svg {
  width: 20px;
  height: auto;
  margin-right: 12px;
  fill: #80868b;
}
.seo-preview-link,
.seo-preview-title {
  overflow: hidden;
  font-family: Arial, sans-serif;
  line-height: 1.3;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
}
.seo-preview-link {
  max-width: 340px;
  margin: 2px 0;
  color: #202124;
  font-size: 14px;
}
.seo-preview-title {
  display: inline-block;
  margin-bottom: 3px;
  padding-top: 4px;
  color: #1a0dab;
  font-size: 20px;
  -webkit-text-overflow: ellipsis;
}
.seo-preview-link,
.seo-preview-title {
  overflow: hidden;
  font-family: Arial, sans-serif;
  line-height: 1.3;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
}
.seo-preview-desc {
  color: #4d5156;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

/* Twitter */

.twitter-container {
  width: 591px;
  margin-bottom: 2.4rem;
  border: 1px solid #18191b;
  background: #fff;
  border-radius: 3px;
}

.social-twitter-post-preview {
  overflow: hidden;
  color: #292f33;
  font-size: 1.4rem;
  line-height: 1.3em;
  border: 1px solid #c4cfd6;
  background: #fff;
  border-radius: 16px;
  -webkit-font-smoothing: antialiased;
}
.social-twitter-preview-content {
  padding: 10px;
}
.social-twitter-preview-image {
  width: 100%;
  height: 265px;
  border-bottom: 1px solid #c4cfd6;
  background-size: cover;
  background-position: 50%;
}
.social-twitter-preview-title {
  width: 487px;
  max-height: 20px;
  overflow: hidden;
  overflow-wrap: break-word;
  margin: 0 0 2px;
  color: #0f1419;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.social-twitter-preview-desc {
  width: 100%;
  max-width: 487px;
  max-height: 40px;
  overflow: hidden;
  overflow-wrap: break-word;
  margin-top: 5px;
  color: #5b7083;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}
.social-twitter-preview-meta {
  display: flex;
  align-items: center;
  overflow: hidden;
  overflow-wrap: break-word;
  width: 487px;
  margin-top: 2px;
  color: #5b7083;
  font-size: 15px;
  line-height: 1.3125;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.social-twitter-reactions {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-shrink: 0;
  max-width: 425px;
  margin-top: 10px;
  color: #5b7083;
  font-size: 13px;
  font-weight: 400;
}
/* Facebook */

.og-container {
  width: 476px;
  margin-bottom: 2.4rem;
  border: 1px solid #18191b;
  background: #fff;
  border-radius: 3px;
}
.social-og-title {
  color: #385898;
  font-size: 14;
  line-height: 1.38;
  font-weight: 600;
}
.social-og-time {
  color: #616770;
  font-size: 12px;
  line-height: 1.34;
  font-weight: 400;
}
.social-og-desc {
  display: inline-block;
  height: 10px;
  background: #e9ebee;
  border-radius: 5px;
}
.social-og-desc {
  display: inline-block;
  height: 10px;
  background: #e9ebee;
  border-radius: 5px;
}

.social-og-preview {
  background: #f2f3f5;
}
.social-og-preview-bookmark {
  padding: 10px 12px 8px;
  border-bottom: 1px solid #dddfe2;
}
.social-og-preview-image {
  width: 100%;
  height: 247px;
  border-top: 1px solid #dddfe2;
  background-size: cover;
  background-position: 50%;
}
.social-og-preview-content {
  height: 69px;
  overflow: hidden;
  margin: 0 -1px 0 -4px;
  padding: 0 1px 0 4px;
}

.social-og-preview-meta {
  overflow: hidden;
  color: #606770;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.social-og-preview-title {
  max-height: 110px;
  overflow: hidden;
  margin: 3px 0 0;
  padding-top: 2px;
  color: #1d2129;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  word-wrap: break-word;
}
.social-og-preview-desc {
  max-height: 20px;
  overflow: hidden;
  margin-top: 3px;
  color: #4b4f56;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-word;
}
.social-og-reactions {
  display: flex;
  align-items: center;
  margin: 12px;
}
.social-og-likes {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  color: #606770;
  font-size: 13px;
  line-height: 20px;
}
.social-og-likes svg {
  width: 20px;
  height: 20px;
}
.social-og-comments {
  display: flex;
  flex-shrink: 0;
  color: #606770;
  font-size: 13px;
  line-height: 20px;
  word-wrap: break-word;
}

.form-container {
  background-color: #f0f0f0;
  width: 500px;
  height: 100%;
  padding: 20px;
  margin: auto;
}

@media (max-width: 1080px) {
  .twitter-container {
    width: 100%;
    max-width: 591px;
  }
  .og-container {
    width: 100%;
    max-width: 476px;
  }
}
@media (max-width: 1360px) {
  .meta-data-container .ant-collapse-content-box {
    flex-direction: column;
  }
  .seo-container {
    max-width: 591px;
  }
}
@media (min-width: 1080px) {
  .preview-form-container {
    flex-basis: 67%;
  }
  .preview-container {
    flex-basis: 33%;
  }
}
@media (min-width: 1360px) {
  .seo-container {
    max-width: 1091px;
  }
}
