.slider-menu {
  height: calc(100vh - 165px);
}

.menu-header {
  height: 64px;
  line-height: 64px;
}

.menu-logo {
  max-height: 48px;
  max-width: 48px;
  width: 100%;
  object-fit: contain;
}

.menu-company {
  color: #ffffff;
  font-size: 24px;
  padding-left: 16px;
}

.user-menu > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 50px;
  padding: 0.25rem;
}

.user-menu > div:hover {
  background-color: #fff;
}

.user-menu > div > span {
  display: flex;
}

.stats-wrapper {
  background: #f0f2f5;
  margin: 1rem auto;
}

.ant-menu-item-selected {
  font-weight: 600;
}

/* .ant-btn.button-secondary {
  color: #fff;
  background: #15171a;
  font-weight: 500;
}

.ant-btn.button-secondary:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn.button-secondary:hover,
.ant-btn.button-secondary:focus,
.ant-btn.button-secondary:active {
  background: #fff;
  color: #15171a;
  text-decoration: none;
}

.ant-btn.button-secondary:hover,
.ant-btn.button-secondary:focus {
  color: #15171a;
  background: #fff;
  border-color: #15171a;
} */

.layout-header {
  background-color: #ffffff;
  padding-left: 16px;
}

.main-sidebar .slider-menu {
  overflow-y: auto;
  overflow-x: hidden;
}

/* Add In Ant Style */
.ant-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.ant-space {
  width: 100%;
}

/* .ant-form {
  max-width: 600px;
} */

/* prosemirror default styles unset for list items in antd */
ul.ant-pagination li::before {
  background: none;
  background-position: initial;
  content: normal;
  display: inline;
  cursor: auto;
  width: auto;
  height: auto;
  position: static;
  left: auto;
  opacity: 1;
  transition: none;
}

nav.ant-breadcrumb ol li::before {
  background: none;
  background-position: initial;
  content: normal;
  display: inline;
  cursor: auto;
  width: auto;
  height: auto;
  position: static;
  left: auto;
  opacity: 1;
  transition: none;
}

.post-description .codex-editor {
  font-size: 1.25rem;
}
